<template>
  <div>
    <b-row>
      <b-col>
        Agents Under {{buyer.name}}
        <div>
          <b-button v-b-modal.add-agents variant="outline-primary"
            ><u>Add Agent</u></b-button
          >
        </div>
      </b-col>
      <b-col>
        <b-form-group>
          <b-form-input
            v-model="searchfield"
            placeholder="Search Name..."
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <br/>

    <b-table
      id="AgentsTable"
      :items="buyer.agents"
      show-empty
      empty-text="No village agents added yet"
      :fields="fields"
    >
      <template #cell(actions)="data">
        <b-dropdown
          v-show="false"
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon-three-dots-vertical class="text-dark"></b-icon-three-dots-vertical>
          </template>
          <b-dropdown-item @click="deregister(data.item.vaId)">
            <b-icon-trash class="text-danger"></b-icon-trash>
            <span class="text-danger">De-register</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <add-agent ></add-agent>
  </div>
</template>
<script>
// import moment from 'moment';
import AddAgent from "./components/AddAgents.vue";

export default {
  components: { AddAgent },
  computed: {
    buyer() {
      return this.$store.getters["buyera/getProfile"];
    },
  },
  data() {
    return {
      searchfield: null,
      fields: [
        {
          key: "va_name",
          label: "Name",
        },
        {
          key: "fcount",
          label: "Farmers",
        },
        {
          key: "time",
          label: "Date",
          formatter:(dat)=>{
            
            return dat.split("T")[0];
          }
        },
        // {key:"actions"}
      ],
    };
  },
  mounted() {},
  methods:{
    deregister(vaId){
      console.log(vaId);
    }
  }
};
</script>
