import { axiosApiInst } from "../../../../plugins/axios"
const BASE_URL = process.env.VUE_APP_BACKEND + "/buyers"
const AGENTS_URL = process.env.VUE_APP_BACKEND +"/api/v3/village-agents"

/**
 * @typedef Produce
 * @property {string} farmer_name
 * @property {number} amount_used
 * @property {string} transaction_date
 * @property {string} agent_name
 * @property {number} quantity_supplied 
 */
/**
 * @typedef Wallet
 * @property {number} balance
 */

/**
 * @typedef Va
 * @property {string} vaId
 * @property {number} fcount
 * @property {string} va_name
 */

/**
 * @typedef Buyer
 *  @property {string} id
 *  @property {Wallet} wallet
 *  @property {string} value_chain
 * @property {string} name
 * @property {string} village
 * @property {string} phone_no
 * @property {string} district
 * @property {Array<Va>} agents
 * @property {Array<Produce>} produce
 */

/**
 * @typedef Agent
 * @property {string} id
 * @property {string} va_name
 * @property {string} va_district
 * @property {string} va_phonenumber
 * @property {string} va_village
 */

/**
 * @typedef Transaction 
 * @property {string} id
 * @property {string} donor
 * @property {Wallet} donor_w
 * @property {string} recepient
 * @property {Wallet} recepient_w
 * @property {string} category
 * @property {string} kind
 * @property {number} amount
 * 
 */

/**
 * 
 * @returns {Promise<Buyer[]>}
 */
async function blist() {
    let res = await axiosApiInst({
        url: BASE_URL + "/"
    });
    return res.data;
}
/**
 * @returns {Promise<Agent[]>}
 */
async function find_vas(){
    let res = await axiosApiInst({
        url: AGENTS_URL+"/"
    });
    return res.data.data;
}
/**
 * 
 * @param {Buyer} buyer
 * @returns {Promise<Buyer>} 
 */
async function register(buyer){
    buyer["name"] = buyer["name"].split(",")[0]
    let res = await axiosApiInst({
        url:BASE_URL+"/",
        data:buyer,
        method:'post'
    });
    return res.data;
}
export { blist,find_vas,register}