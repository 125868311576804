<template>
  <b-modal
    title="Add Agent to Buyer"
    id="add-agents"
    hide-footer
    v-model="isShown"
  >
    <b-overlay :show="isBusy" rounded="sm" spinner-variant="primary">
      <b-form @submit.prevent="addAgent">
        <b-form-group label="Agent Name">
          <vue-bootstrap-typeahead
            v-model="form.name"
            :serializer="(s) => s.va_name + ',' + s.va_phonenumber"
            :data="agents"
            @hit="onUserSelect"
          >
          </vue-bootstrap-typeahead>
        </b-form-group>
        <b-form-group label="Phone Number">
          <b-form-input required disabled :value="form.phone_no"></b-form-input>
        </b-form-group>
        <b-form-group label="District">
          <b-form-input required disabled :value="form.district"></b-form-input>
        </b-form-group>
        <b-form-group label="Village">
          <b-form-input required disabled :value="form.village"></b-form-input>
        </b-form-group>
        <div class="w-100">
          <b-button type="submit" variant="primary" class="fl-rht"
            >Add Agent</b-button
          >
          <b-button variant="outline-primary" class="fl-rht mr-1" @click="close"
            >Cancel</b-button
          >
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<style scoped>
.fl-rht {
  float: right;
}
</style>
<script>
import * as agentApi from "../../../api";
import * as api from "../../../../../api";
export default {
  data() {
    return {
      form: {
        name: "",
        user_type: "va",
        phone_no: "",
        district: "",
        village: "",
      },
      user_types: [
        { text: "Village Agent", value: "va" },
        // {label:"Farmer Group",value:"farmer_group"}
      ],
      agents: [],
      isShown: false,
      isBusy: false,
    };
  },
  mounted() {
    api.find_vas().then((data) => {
      this.agents = data;
    });
  },
  methods: {
    /**
     * @param {api.Agent} user
     */
    onUserSelect(user) {
      this.form.district = user.va_district;
      this.form.name = user.va_name;
      this.form.phone_no = user.va_phonenumber;
      this.form.id = user.id;
      this.form.village = user.va_village;
    },

    addAgent() {
      this.isBusy = true;
      const data = {
        agent_id: this.form.id,
      };
      agentApi
        .add_agent(this.$route.params.buyer_id, data)
        .then((data) => {
          this.$emit("buyer-added", data);
          this.reset();
          
          this.isBusy = false;
          this.$bvToast.toast("Successfully added");
          this.isShown = false;
          this.reloadProfile();
        })
        .catch((error) => {
          this.isBusy = false;
          this.$bvToast.toast("Failed", {
            title: "Failed to add Agent",
            variant: "danger",
            solid: true,
          });
          this.$bvToast.toast(error.response.data.message, {
            title: "Failed to add Agent",
            variant: "danger",
            solid: true,
          });
        });
    },
    reloadProfile() {
      agentApi.find_one(this.$route.params.buyer_id).then((data) => {
        this.$store.dispatch("buyera/saveProfile", data);
      });
    },
    reset() {
      this.form = {
        name: "",
        user_type: "va",
        phone_no: "",
        district: "",
        village: "",
      };
    },
    close() {
      this.reset();
      this.isBusy = false;
      this.isShown = false;
    },
  },
};
</script>
